import { post } from './request'
import store from '@/store'

/**
 * @param data
 * {
 *   type: 要跳转的路由  例：'/index'
 *   param: 跳转需带的参数；类型：json字符串，无参数不传
 * }
 */
export function goOtherApp(data = {}) {
  // console.log(store.getters)
  let obj = Object.assign(data, {
    memberId: store.getters.memberInfo.memberId,
    companyAuthId: store.getters.companyAuthId,
    language: store.getters.languageType,
  })
  console.log(obj)
  // location.href = 'https://workbench.caacmall.com/#/redirect?token=xxx&type=certification'
  // return;
  post('/v3/member/front/login/ecryptData', {
    dataInfo: JSON.stringify(obj)
  }).then(res => {
    if (res.state == 200) {
      // location.href = 'http://localhost:9999/#/redirect?token=' + encodeURIComponent(res.data);
      // window.open('http://localhost:9999/#/redirect?token=' + encodeURIComponent(res.data))
      // window.open('https://workbench.caacmall.com/#/redirect?token=' + encodeURIComponent(res.data));
      window.open('http://workbench.caacmall.cn/#/redirect?token=' + encodeURIComponent(res.data));
      // window.open('http://workbench.caacmall.net/#/redirect?token=' + encodeURIComponent(res.data));
    }
  })
}

/**
 * @param data
 * {
 *   type: 要跳转的路由  例：'/index'
 *   param: 跳转需带的参数；类型：json字符串，无参数不传
 * }
 */
export function goOtherAppPage(data = {}) {
  console.log(store.getters)
  let obj = Object.assign(data, {
    memberId: store.getters.memberInfo.memberId,
    companyAuthId: store.getters.companyAuthId,
    language: store.getters.languageType,
  })
  console.log(obj)
  // location.href = 'https://workbench.caacmall.com/#/redirect?token=xxx&type=certification'
  // return;
  post('/v3/member/front/login/ecryptData', {
    dataInfo: JSON.stringify(obj)
  }).then(res => {
    if (res.state == 200) {
      // location.href = 'http://localhost:9999/#/redirect?token=' + encodeURIComponent(res.data);
      // window.open('http://localhost:9999/#/redirect?token=' + encodeURIComponent(res.data))
      // location.href = 'https://workbench.caacmall.com/#/redirect?token=' + encodeURIComponent(res.data);
      location.href = 'http://workbench.caacmall.cn/#/redirect?token=' + encodeURIComponent(res.data);
      // location.href = 'http://workbench.caacmall.net/#/redirect?token=' + encodeURIComponent(res.data);
    }
  })
}
